<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 pb-4 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title
                  >Order  : {{ invoice.orderID }}
                  <v-spacer></v-spacer>
                  <v-btn icon color="info" @click="prevPage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon color="info" @click="nextPage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span></v-row
                  > -->

                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        disabled
                        color="black"
                        label="Bill to company"
                        outlined
                        dense
                        v-model="invoice.billToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        disabled
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="invoice.billToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="City"
                        outlined
                        dense
                        v-model="invoice.billToCity"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" style="padding-bottom: 0px !important">
                      <v-select
                        ref="state"
                        disabled
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="invoice.billToStateId"
                        :items="states"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Zip"
                        outlined
                        dense
                        v-model="invoice.billToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span></v-row
                  > -->

                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Ship To Company"
                        outlined
                        dense
                        v-model="invoice.shipToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Address"
                        outlined
                        disabled
                        dense
                        v-model="invoice.shipToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="City"
                        outlined
                        dense
                        v-model="invoice.shipToCity"
                      ></v-text-field>
                    </v-col>

                    <v-col md="1" style="padding-bottom: 0px !important">
                      <v-select
                        :items="states"
                        ref="state"
                        disabled
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="invoice.shipToStateId"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Zip"
                        disabled
                        outlined
                        dense
                        v-model="invoice.shipToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      style="padding-bottom: 0px !important"
                    >
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="invoice.orderDate"
                            disabled
                            label="Order Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.orderDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      style="padding-bottom: 0px !important"
                    >
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="invoice.shippingDate"
                            disabled
                            label="Shipping Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.shippingDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Customer P.O"
                        outlined
                        dense
                        v-model="invoice.customerPO"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        v-if="invoice.lastOrderId"
                        disabled
                        color="black"
                        label="Order No.(Last Order)"
                        outlined
                        dense
                        v-model="invoice.lastOrderId.orderID"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        disabled
                        color="black"
                        label="Order No.(Last Order)"
                        outlined
                        dense
                        
                      ></v-text-field>
                    </v-col>

                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Phone"
                        outlined
                        dense
                        v-model="invoice.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Email"
                        outlined
                        dense
                        v-model="invoice.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-select
                        label="Ship Via"
                        disabled
                        v-model="invoice.shipMethodId"
                        :items="shipvia"
                        item-text="name"
                        item-value="_id"
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        ref="terms"
                        disabled
                        color="black"
                        label="Terms"
                        outlined
                        v-model="invoice.terms"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        
                        color="black"
                        label="Agent"
                        outlined
                        dense
                        disabled
                        v-model="invoice.agentCode"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        v-if="invoice.customerId != null"
                        color="black"
                        disabled
                        label="Customer No"
                        outlined
                        dense
                        :rules="customer"
                        v-model="invoice.customerId.customerID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense style="width: 100% !important">
                    <v-col md="4" class="text-left"
                      ><p class="pt-4">Orders</p></v-col
                    >
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="productinfo"
                  hide-default-footer
                >
                  <template v-slot:item.isTaxable="{ item }">
                    <v-simple-checkbox
                      v-model="item.isTaxable"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>

                <v-card-title>
                  <v-row dense class="justify-end">
                    <v-col md="2">
                      <v-text-field
                        disabled
                        color="black"
                        label="Sales Tax"
                        outlined
                        dense
                        v-model="salesTax"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        disabled
                        color="black"
                        label="Est.Total"
                        outlined
                        dense
                        v-model="estTotal"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1" v-if="notes.length > 0">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="12">
                          <v-text-field
                            ref="Notes"
                            color="black"
                            label="Note"
                            dense
                            disabled
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 5%"
      right
      width="55"
    >
      <v-list nav dense>
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon color="primary" @click="Print = true">mdi-file-pdf</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="Print" max-width="500">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Print
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-radio-group v-model="printType" row>
                    <v-radio label="Order Copy" value="copy"></v-radio>
                    <v-radio label="Packing List" value="packinglist"></v-radio>
                    <v-radio label="Blank P/L" value="blankpackinglist"></v-radio>
                  </v-radio-group>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="Print = false"> Cancel </v-btn>
                <v-btn color="primary" class="mr-4" @click="Printdata">
                  View Print
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="emailDialog = true"
              >mdi-gmail</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="emailDialog" max-width="800">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                ABB Labels
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="sendfrom"
                    label="Send From"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="sendto"
                    label="Send To"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <vue-editor
                    v-model="message"
                    placeholder="Message"
                     color="#26af82"
                    :editorToolbar="customToolbar"
                  ></vue-editor>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="emailDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="sendMail">
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-list-item
          link
          :to="'/editOrder/' + $route.params.id"
          title="Edit Order"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Attachment">
          <v-list-item-icon v-if="files.length > 0">
            <a
              target="_blank"
              :href="'https://www.abblabels.ml/file/get/' + files[0]"
              class="downlink"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </a>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon color="grey" class="mr-2"> mdi-eye-off </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{ name: 'addInvoice', params: { orderid: $route.params.id } }"
          title="Add Invoice"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      orderid: null,
      emailDialog: false,
      sendfrom: null,
      sendto: null,
      message: null,
      subject: null,
      invoice: {
        billToCompany: null,
        billToStateId: null,
        billToCity: null,
        billToZip: null,
        shipToAddress: null,
        shipToStateId: null,
        shipToCity: null,
        shipToZip: null,
        terms: null,
        email: null,
        phone: null,
        customerPO: null,
        customerId: null,
        shipMethodId: null,
        agentCode:null,
        abbOrderno: null,
        orderDate: new Date().toISOString().substr(0, 10),
        shippingDate: "",
        orderProducts: [],
        deleteProducts: [],
      },
      printType:"copy",
      headers: [
        {
          text: "Order Qty",
          align: "start",
          value: "quantity",
          sortable: false,
        },
        {
          text: "Ship Qty",
          align: "start",
          value: "shipQuantity",
          sortable: false,
        },
        { text: "Description", value: "description", sortable: false },

        { text: "Unit price", value: "unitPrice", sortable: false },

        { text: "Um", value: "um", sortable: false },
        { text: "Tax", value: "isTaxable", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        // { text: "Amt", value: "totalAmount" },
        // { text: "Bal", value: "balance" },
        // { text: "Agent", value: "agentCode" },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      row: "1",
      orderData: [],
      files: [],
      updateProducts: [],
      states: [],
      terms: [],
      shipvia: [],
      menu1: false,
      modal: false,
      menu2: false,
      dialog: false,
      dialogDelete: false,
      salesTax: null,
      taxAmount: null,
      estTotal: null,
      notes: [
        {
          note: "",
        },
      ],

      productinfo: [
        {
          quantity: "",
          shipQuantity: "",
          description: "",
          unitPrice: "",
          um: "",
          amount: "",
          isTaxable: false,
          sr_no: null,
        },
      ],
      deleteProducts: [],
      sn_value: 0,

      qtyrules: [
        (v) => !!v || "Required",
        // v =>
        //   (!!v && v.length <= 10) ||
        //   "Phone number must be less than 10 characters",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
      ],
      shipqtyrules: [(v) => !isNaN(parseFloat(v)) || "Number is Expected"],
      untPrice: [
        (v) => !!v || "Required",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0,
      ],
      descriptionrule: [(v) => !!v || "required"],
      customer: [(v) => !!v || "required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      atfiles: [],
      attachments: [],
      agentCode: localStorage.getItem("agentCode"),
      attachmentsformData: new FormData(),
      showsnackbar: false,
      msg: "",
      timeout: 2000,
      appLoading: false,
      Print: false,
      customToolbar: [[]],
    };
  },

  beforeMount() {
    this.stateList();
    this.termList();
    this.shipList();
    // this.getTax();
    this.getOrder();
  },

  methods: {
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            if (response.data.data.email)
              this.sendfrom = response.data.data.email;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    sendMail() {
      this.emailDialog = false;

      this.sendTo = this.sendto.split(",");
      axios({
        method: "POST",
        url: "/send/email",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          emailType: "order",
          id: this.$route.params.id,
          emailTo: this.sendTo,
          emailFrom: this.sendfrom,
          subject: this.subject,
          message: this.message,
        },
      })
        .then((response) => {
          if(response.data.status){
          this.appLoading = false;
          // console.log(response.data.msg);
          this.message = "";
          this.showsnackbar = true;
          this.msg = "Email sent successfully!";
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    nextPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/order/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderid: this.$route.params.id,
          type: "previous",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Order/" + response.data.data._id);
            this.getOrder();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    prevPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/order/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderid: this.$route.params.id,
          type: "next",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Order/" + response.data.data._id);
            this.getOrder();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getTax() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/tax/view/" + "5f7e94279eeed32c3477263d",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.taxAmount = response.data.data.taxAmount;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getOrder() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/order/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.invoice = response.data.data;
            var agCode = '';
            var uptCode = '';
            if(this.invoice.customerId )
            agCode = this.invoice.customerId.agentCode
           
            if( this.invoice.updated_by)
            uptCode = this.invoice.updated_by.agentCode
            
            this.invoice.agentCode = agCode +"/"+ uptCode;
            if(this.invoice.attachments.length>0)
            this.files = this.invoice.attachments;
            
            this.orderid = this.$route.params.id;
            this.estTotal = this.invoice.totalAmount.toFixed(2);
            this.salesTax = this.invoice.tax.toFixed(2);
            this.productinfo = this.invoice.products;
            if (this.invoice.notes) this.notes = this.invoice.notes;
            if (this.invoice.orderDate)
              this.invoice.orderDate = this.ChangeFormateDate(
                this.invoice.orderDate
              );
            if (this.invoice.shippingDate)
              this.invoice.shippingDate = this.ChangeFormateDate(
                this.invoice.shippingDate
              );
            this.subject = "ABB Label - Order #" + this.invoice.orderID;
            this.sendto = this.invoice.email;
            this.getProfile();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    termList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/term/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.terms = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    shipList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/shippingMethod/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.shipvia = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    update() {
      this.sn_value = this.sn_value + 1;
      this.productinfo.push({
        quantity: "",
        shipQuantity: "",
        description: "",
        unitPrice: "",
        um: "",
        amount: "",
        isTaxable: false,
        sr_no: this.sn_value,
      });
    },
    removee(index, item) {
      this.productinfo.splice(index, 1);
      for (var r = 0; r < this.updateProducts.length; r++) {
        if (this.updateProducts[r].sr_no == item.sr_no) {
          this.updateProducts.splice(r, 1);
        }
      }
      if (item._id) this.deleteProducts.push(item._id);

      var estimate = 0;
      var tax = 0;

      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = (estimate + tax).toFixed(2);
      this.salesTax = tax.toFixed(2);
    },

    addValidate() {
      if (this.$refs.form.validate()) this.editOrder();
    },

    amtComputation(item) {
      var estimate = 0;
      var tax = 0;
      if (item.shipQuantity != 0) {
        item.amount = item.unitPrice * item.shipQuantity;
      } else item.amount = item.unitPrice * item.quantity;

      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = estimate + tax;
      this.salesTax = tax.toFixed(2);
    },
    prdUpdate(item) {
      if (item._id) {
        if (this.updateProducts.length == 0) this.updateProducts.push(item);
        else {
          for (var i = 0; i < this.updateProducts.length; i++) {
            if (this.updateProducts[i]._id == item._id) {
              this.updateProducts.splice(i, 1);
            }
          }
          this.updateProducts.push(item);
        }
      } else {
        if (this.updateProducts.length == 0) this.updateProducts.push(item);
        else {
          for (var k = 0; k < this.updateProducts.length; k++) {
            if (this.updateProducts[k].sr_no == item.sr_no) {
              this.updateProducts.splice(k, 1);
            }
          }
          this.updateProducts.push(item);
        }
      }
    },
    editOrder() {
      this.appLoading = true;
      this.invoice.orderProducts = this.updateProducts;
      this.invoice.deleteProducts = this.deleteProducts;
      axios({
        method: "POST",
        url: "/order/edit/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.invoice,
      })
        .then((response) => {
          if (response.status) {
            this.appLoading = false;
            if (this.attachments) {
              for (var i = 0; i < this.attachments.length; i++) {
                this.attachmentsformData.append(
                  "attachments",
                  this.attachments[i]
                );
              }
              this.uploadattachments();
            }
            this.getOrder();
            this.msg = "Order Edited!";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    formreset() {
      this.$refs.form.reset();
    },

    getFiles() {
      var selectedFiles = this.atfiles[0];

      if (this.atfiles) {
        this.msg = null;
        this.attachments.push(selectedFiles);
      }
      console.log(this.attachments);
    },

    uploadattachments() {
      axios({
        method: "POST",
        url: "/order/upload/attachments/" + this.$route.params.id,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },

        data: this.attachmentsformData,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //this.appLoading = true;
          // vm.uploadMsg = "Uploading " + percentCompleted + "%";
          if (percentCompleted == 100) {
            // this.appLoading = false;
          }
        },
      })
        .then((response) => {
          if (response.status) {
            this.appLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Printdata() {
      this.Print = false;

      window.open(
        "https://www.abblabels.ml/pdf/generate/Order_Details/?orderId=" +
          this.$route.params.id+"&printType="+this.printType,
        "_blank"
      );
    },
    ChangeFormateDate(oldDate) {
      // var newdateFormat = oldDate.toString().split("T");
      // return newdateFormat[0];
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: black !important;
}
.v-text-field input {
  color: grey !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
a {
  text-decoration: none !important;
}
</style>



